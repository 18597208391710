/* Base styles */
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Additional reset and base styles */
* {
  box-sizing: border-box;
}

:root {
  --color-primary: #4285f4;
  --color-error: #f44336;
  --color-success: #34a853;
  --color-warning: #fbbc05;
}