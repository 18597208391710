/* App.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #333;
  background-color: #f9fafb;
  line-height: 1.5;
}

.app-container {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

/* Ensuring text is visible during load */
.text-gray-500 {
  color: #6b7280;
}

.text-gray-600 {
  color: #4b5563;
}

.text-gray-700 {
  color: #374151;
}

.text-gray-800 {
  color: #1f2937;
}

/* Animation for recording indicator */
@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.recording-indicator {
  animation: pulse 2s infinite;
}

/* Custom scrollbars for transcript and suggestion panels */
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: #cbd5e0 #f1f5f9;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 8px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: #f1f5f9;
  border-radius: 4px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: #cbd5e0;
  border-radius: 4px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .h-96 {
    height: 16rem;
  }
}

/* Transitions for smoother UI */
button, 
.cursor-pointer {
  transition: all 0.2s ease-in-out;
}

/* Highlight effect for objections */
.border-l-4.border-red-500 {
  box-shadow: 0 0 0 1px rgba(239, 68, 68, 0.1);
}